import ConnectWithUs from '../ConnectWithUs';
import { useContext, useMemo } from 'react';
import JsxParser from 'react-jsx-parser';
import { useSelector } from 'react-redux';

import Disclaimer from 'components/Disclaimer';
import FooterList from 'components/FooterList';
import Icon from 'components/Icon';
import InverseLogo from 'components/InverseLogo';
import { medicarePlanType } from 'components/PlanTypeModal/constants';

import phoneContext from 'contexts/phone';
import purlAgentContext from 'contexts/purlAgentContext';
import zipcodeContext from 'contexts/zipCode';

import formatPhoneNumber from 'utilities/formatPhoneNumber';
import scrollToTop from 'utilities/scrollToTop';

import envelopeImg from './images/envelope.svg';
import phoneImg from './images/phone.svg';

import {
    companyLinks,
    companyLinks1,
    disclaimerText,
    disclaimerText1,
    disclaimerText2,
    disclaimerText2WhenZipAvailable,
    disclaimerText3,
    disclaimerText4,
    disclaimerText5,
    learningCenterLinks,
    iulDisclaimerText,
    iulDisclaimerText1
} from './constants';

import styles from './styles.module.scss';
import {
    LEARNING_CENTER_DETAIL_PAGE,
    PLAN_TYPE_KEY
} from 'utilities/storageKeys';
import { get } from 'temp/utilities/storage';
import { PLANS_ROUTE, REQUEST_CALL_BACK } from 'utilities/routes';
import { PLAN_TYPE_FILTER_IUL } from 'components/FinalExpensePlanTypeTabs/utility/constants';
import { PLAN_TYPE_FILTER_FE } from 'components/FinalExpenseContentContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEmail, faPhone } from '@awesome.me/kit-cc709582e6/icons/kit/custom';

const Footer = ({ className = '', shouldHideLinks = false }) => {
    const { phone } = useContext(phoneContext);
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );

    const { purlAgentData } = useContext(purlAgentContext);
    const zipCodeContextMap = useContext(zipcodeContext);

    const { email, virtualNumber } = purlAgentData || {};

    const { text = '' } = phone;

    const questionsLinks = [
        {
            link: '/',
            text: `Call ${text} to speak with a licensed insurance agent`
        }
    ];

    const agentContactDetails = purlAgentData && [
        {
            link: `mailto:${email}`,
            externalURL: true,
            text: (
                <>
                    <FontAwesomeIcon
                        className={styles.envelopeIcon}
                        color="#fff"
                        icon={faEmail}
                        id="envelopeIcon"
                        size="xl"
                    />{' '}
                    {email}
                </>
            ),
            id: 'footerListEmail'
        },
        {
            link: `tel:${virtualNumber}`,
            externalURL: true,
            text: (
                <>
                    <FontAwesomeIcon
                        className={styles.phoneIcon}
                        color="#fff"
                        icon={faPhone}
                        id="phoneImg"
                        size="xl"
                    />{' '}
                    {formatPhoneNumber(virtualNumber?.substring(2))}
                </>
            ),
            id: 'footerListPhoneNumber'
        }
    ];

    const medicareUrlsWithoutMedicare = [
        '/guide/enrollment',
        '/guide/coverage-options'
    ];

    const isGenericPage =
        window.location.pathname === '/' ||
        (window.location.href.indexOf('final') === -1 &&
            window.location.href.indexOf('medicare') === -1 &&
            !medicareUrlsWithoutMedicare.includes(window.location.pathname));

    const showHomeFooterContent =
        window.location.href.indexOf('final') === -1 ||
        window.location.pathname === '/';

    const isFinalExpenseProducts =
        window.location.href.indexOf('life') > -1 &&
        get(PLAN_TYPE_KEY) === PLAN_TYPE_FILTER_FE;

    const showAllContent =
        window.location.href.indexOf('final') === -1 &&
        (get(LEARNING_CENTER_DETAIL_PAGE)
            ? selectedPlanType === medicarePlanType
            : isFinalExpenseProducts
              ? false
              : true);

    const showFinalExpense =
        window.location.href.indexOf('final') === -1 &&
        (get(LEARNING_CENTER_DETAIL_PAGE) ||
        [PLANS_ROUTE, '/plan', REQUEST_CALL_BACK].includes(
            window.location.pathname
        )
            ? selectedPlanType === medicarePlanType
            : false);

    const showIulFinalExpense =
        window.location.href.indexOf('life') > -1 &&
        get(PLAN_TYPE_KEY) === PLAN_TYPE_FILTER_IUL;

    const wrapPlanCount = useMemo(
        () => zipCodeContextMap?.planCount.toLocaleString(),
        [zipCodeContextMap]
    );

    const getDisclaimer2Text = useMemo(
        () =>
            zipCodeContextMap.zipCode
                ? disclaimerText2WhenZipAvailable
                : disclaimerText2,
        [zipCodeContextMap.zipCode]
    );

    return (
        <footer
            className={`${styles.footer} ${className} ${
                shouldHideLinks ? styles.footerNoLinks : ''
            }`}
            data-gtm="footer"
            id="plan-footer"
        >
            <InverseLogo
                className={styles.logo}
                linkClassName={styles.logoLinkClassName}
            />

            {!shouldHideLinks && (
                <FooterList
                    className={styles.learningCenter}
                    items={learningCenterLinks}
                    onClick={scrollToTop}
                    title="Know the Basics"
                    id="know-the-basics"
                />
            )}

            {!shouldHideLinks && (
                <FooterList
                    className={styles.company}
                    items={purlAgentData ? companyLinks1 : companyLinks}
                    onClick={scrollToTop}
                    title="Company"
                    id="company"
                />
            )}

            {!shouldHideLinks && (
                <FooterList
                    className={styles.questions}
                    items={purlAgentData ? agentContactDetails : questionsLinks}
                    onClick={scrollToTop}
                    title="Questions?"
                    id="questions"
                />
            )}
            <ConnectWithUs className={styles.connectWithUs} />

            <Disclaimer className={styles.disclaimer}>
                <JsxParser
                    bindings={{
                        disclaimerLink: styles.disclaimerLink
                    }}
                    jsx={`
                  ${
                      showAllContent
                          ? `<p class="${
                                styles.disclaimerText
                            }">${showIulFinalExpense ? iulDisclaimerText : disclaimerText}</p>
                  <p class="${styles.disclaimerText}">${showIulFinalExpense ? iulDisclaimerText1 : disclaimerText1}</p>
                 
                 ${
                     showIulFinalExpense
                         ? `<p class="${styles.disclaimerText}"></p>`
                         : `<p class="${
                               !showHomeFooterContent &&
                               styles.disclaimerTextShow
                           } ${styles.disclaimerText}">${getDisclaimer2Text.replace(
                               /\$\{([^}]+)\}/g,
                               (_, group) => {
                                   return group.trim() === 'wrapPlanCount'
                                       ? wrapPlanCount
                                       : zipCodeContextMap[group.trim()];
                               }
                           )}</p>
                  <p class="${
                      !showHomeFooterContent && styles.disclaimerTextShow
                  } ${styles.disclaimerText}">${disclaimerText3}</p>
                  <p class="${styles.disclaimerText}">${disclaimerText4}</p>
                  ${
                      showFinalExpense || isGenericPage
                          ? `<p
                              class="${
                                  showFinalExpense && styles.disclaimerTextShow
                              } ${styles.disclaimerText}">
                              ${disclaimerText5}
                          </p>`
                          : ``
                  }
`
                 }`
                          : `<p class="${
                                selectedPlanType === medicarePlanType &&
                                styles.disclaimerTextShow
                            } ${styles.disclaimerText}">${disclaimerText5}</p>`
                  }
                  `}
                />
            </Disclaimer>
        </footer>
    );
};

export default Footer;

import { memo, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styled from '@emotion/styled';

import { Tooltip } from '@mui/material';

import Icon from 'components/Icon';
import LinkWithArrow from 'components/LinkWithArrow';
import Phone from 'components/Phone';
import {
    finalExpensePlanProductType,
    finalExpensePlanType,
    medicarePlanProductType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';
import { setSelectedPlanType } from 'components/PlanTypeModal/slice';
import ProfilePicture from 'components/ProfilePicture';
import { AgentAvailableComponent } from 'components/PurlBannerAgentInfo';
import Text from 'components/Text';

import hamburgerContext from 'contexts/hamburger';
import phoneContext from 'contexts/phone';

import { AGENT_API_BASE_URL } from 'utilities/env';
import { formatPhoneNumberWithBrackets } from 'utilities/formatPhoneNumber';
import {
    AGENT_BIO_ROUTE,
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    IUL_REQUEST_CALL_BACK,
    REQUEST_CALL_BACK
} from 'utilities/routes';

import health from './images/Health.svg';
import life from './images/Life.svg';
import envelope from './images/envelope.svg';
import networkAgentIcon from './images/networkAgent.svg';
import phoneIcon from './images/phone.svg';

import styles from './styles.module.scss';

import { get, set } from 'temp/utilities/storage';

import './styles.scss';
import { IUL_TOGGLE } from '../../utilities/env';

export const AgentType = ({ isPreferredAgent, isNetworkAgent, className }) => {
    return (
        (isPreferredAgent || isNetworkAgent) && (
            <div className={`${styles.agentTypeContainer} ${className}`}>
                {isPreferredAgent && (
                    <span className={styles.preferredAgentBox}>
                        Preferred Agent
                    </span>
                )}
                {isNetworkAgent && (
                    <div className={styles.networkAgentBox}>
                        <Icon
                            className={styles.networkAgentIcon}
                            image={networkAgentIcon}
                        />
                        <span className={styles.networkAgentText}>
                            Network Agent
                        </span>
                    </div>
                )}
            </div>
        )
    );
};

const AssignedAgent = ({ tab }) => {
    let history = useHistory();
    set('isFromProfile', true);

    const { phone } = useContext(phoneContext);
    const { text = '', number } = phone;
    const [agentData, setAgentData] = useState({});
    const [agentDataCache, setAgentDataCache] = useState({});
    const [errorResponse, setErrorResponse] = useState(false);
    const defaultTab =
        IUL_TOGGLE === 'true' ? 'indexedUniversalLife' : 'finalExpense';
    const [lifeAgentTab, setLifeAgentTab] = useState(defaultTab);
    const [selectedProfilePlanType, setSelectedProfilePlanType] = useState();
    const dispatch = useDispatch();

    const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );
    const purlAgents = useSelector(state => state.mainDetails.purlAgents);

    useEffect(() => {
        setSelectedProfilePlanType(
            tab === 1 ? finalExpensePlanType : medicarePlanType
        );
        dispatch(
            setSelectedPlanType(
                tab === 1 ? finalExpensePlanType : medicarePlanType
            )
        );
        const agentMedicare = purlAgents.find(
            pa => pa.productType === medicarePlanProductType
        );
        const agentFinalExpense = purlAgents.find(
            pa => pa.productType === finalExpensePlanProductType
        );
        agentMedicare && set('ProfilePurlAgentMedicareData', agentMedicare);
        agentFinalExpense &&
            set('ProfilePurlAgentFinalExpenseData', agentFinalExpense);

        const agentInfo =
            purlAgents.length > 1
                ? tab === 1
                    ? agentFinalExpense
                    : agentMedicare
                : get(
                      tab === 1
                          ? 'ProfilePurlAgentFinalExpenseData'
                          : 'ProfilePurlAgentMedicareData'
                  );
        if (agentDataCache[tab]) {
            setAgentData(agentDataCache[tab]);
        } else {
            setAgentData({});
            const getData = async () => {
                try {
                    const response = await fetch(
                        `${AGENT_API_BASE_URL}/Purl/${agentInfo?.agentPurlCode}`
                    );
                    if (!response.ok) {
                        throw new Error(`Response status: ${response.status}`);
                    }

                    const json = await response.json();
                    setAgentDataCache({
                        ...agentDataCache,
                        [tab]: json
                    });
                    setErrorResponse(false);
                    setAgentData(json);
                } catch (error) {
                    setErrorResponse(true);
                }
            };
            agentInfo && getData();
        }
    }, [tab, purlAgents, agentDataCache, setAgentDataCache]);

    const { isMobile } = useContext(hamburgerContext);

    const { agentProfessionalInfoResponse } = agentData || {};
    const {
        agentOfficeLocation: office,
        formattedHours = '',
        experience = ''
    } = agentProfessionalInfoResponse || {};
    const { city = '', state = '', streetAddress = '' } = office || {};
    const availableHours =
        formattedHours?.split(',').length > 1
            ? chunk(formattedHours?.split(',') || [], 2)
            : formattedHours?.split(',');

    const phoneNumber = agentData?.virtualNumber?.substring(2);
    const isNetworkAgent = agentData?.isTrainingComplete;
    const isPreferredAgentHealth =
        agentData?.hasActiveCampaigns?.hasActiveHealthCallCampaign;
    const isPreferredAgentLife =
        agentData?.hasActiveCampaigns?.hasActiveLifeCallCampaign;

    const findAgentTitle = !tab
        ? 'Health Agent'
        : lifeAgentTab === 'finalExpense'
          ? 'Final Expense Agent'
          : 'Indexed Universal Life Agent';

    const onClick = () => {
        if (!tab)
            history.push(
                selectedProfilePlanType === finalExpensePlanType
                    ? FINAL_EXPENSE_REQUEST_CALL_BACK
                    : REQUEST_CALL_BACK
            );
        else {
            history.push(
                lifeAgentTab === 'finalExpense'
                    ? FINAL_EXPENSE_REQUEST_CALL_BACK
                    : IUL_REQUEST_CALL_BACK
            );
        }
    };
    const onClickViewMore = () => {
        history.push(`${AGENT_BIO_ROUTE}?purl=${agentData.agentPurlCode}`);
    };

    const NameWithStatus = () => {
        return (
            <div className={styles.nameWithStatus}>
                <div className={styles.nameWithAvailability}>
                    <Text
                        id="assignedAgentName"
                        text={`${agentData?.agentFirstName} ${agentData?.agentLastName}`}
                        className={styles.name}
                    />
                    {!isMobile && (
                        <>
                            <div className={styles.subTextContainer}>
                                <Text
                                    id="assignedAgentType"
                                    text={
                                        selectedProfilePlanType ===
                                        medicarePlanType
                                            ? `Health Agent`
                                            : `Life Agent`
                                    }
                                    className={styles.subTextBold}
                                />
                                <Text
                                    text={
                                        selectedProfilePlanType ===
                                        medicarePlanType
                                            ? `(Medicare)`
                                            : `(Final Expense)`
                                    }
                                    className={styles.subText}
                                />
                                {experience > 0 && (
                                    <Text
                                        text={` | Experience`}
                                        className={styles.subTextBold}
                                    />
                                )}
                                {experience > 0 && (
                                    <Text
                                        id="assignedAgentExperience"
                                        text={`(${experience} years)`}
                                        className={styles.subText}
                                    />
                                )}
                            </div>
                            <div className={styles.subTextContainer}>
                                {(city || state) && (
                                    <Text
                                        id="assignedAgentAddress"
                                        text={`${city}${
                                            city && state && ', '
                                        }${state}${(city || state) && ' | '}`}
                                        className={styles.addressText}
                                    />
                                )}
                                {streetAddress && (
                                    <Text
                                        id="assignedAgentStreetAddress"
                                        text={`${streetAddress} | `}
                                        className={styles.addressText}
                                    />
                                )}
                            </div>
                            {formattedHours && availableHours.length === 1 && (
                                <div className={styles.subTextContainer}>
                                    <Text
                                        id="assingedAgentHours"
                                        text={availableHours}
                                        className={styles.availableHours}
                                    />
                                </div>
                            )}

                            {availableHours?.length > 1 && (
                                <div className={styles.availableHoursContainer}>
                                    {availableHours.map((hour, i) =>
                                        hour.length > 1 ? (
                                            <Text
                                                text={`${hour[0]}, ${hour[1]},`}
                                                className={styles.hourText}
                                                key={i}
                                            />
                                        ) : (
                                            <Text
                                                text={`${hour[0]}`}
                                                className={styles.hourText}
                                                key={i}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                            {!isMobile && (
                                <LinkWithArrow
                                    text={'View Agent'}
                                    onClick={onClickViewMore}
                                    className={styles.linkText}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    };

    const PhoneEmail = () => {
        return (
            (agentData?.email || phoneNumber) && (
                <div className={styles.phoneEmail}>
                    {agentData?.email && (
                        <Tooltip title={agentData?.email} arrow>
                            <a
                                className={styles.link}
                                href={`mailto:${agentData?.email}`}
                                rel="noreferrer"
                            >
                                <Icon
                                    className={`${styles.envelopeIcon}`}
                                    image={envelope}
                                />
                                <p
                                    id="assignedAgentEmail"
                                    className={styles.mail}
                                >
                                    {agentData?.email}
                                </p>
                            </a>
                        </Tooltip>
                    )}
                    {phoneNumber && (
                        <a
                            className={styles.link}
                            href={`tel:${phoneNumber}`}
                            id="assingedAgentPhone"
                            rel="noreferrer"
                        >
                            <Icon
                                className={`${styles.phoneIcon}`}
                                image={phoneIcon}
                            />
                            {formatPhoneNumberWithBrackets(phoneNumber)}
                        </a>
                    )}
                </div>
            )
        );
    };

    const AgentContactWithType = () => {
        return (
            <div className={styles.agentInfoBody2}>
                <AgentAvailableComponent
                    isPurlToggledOn={agentData?.isPurlToggledOn}
                    labelClassName={styles.availableText}
                    text={'Online'}
                    isNetworkAgent={
                        agentData?.agentCompletedTraining === 'True'
                    }
                    toggleStatus={agentData?.agentToggleStatus === 'True'}
                />
                <PhoneEmail
                    phoneNumber={phoneNumber}
                    email={agentData?.email}
                />
            </div>
        );
    };

    const AgentInfo = () => {
        return (
            <div className={styles.agentInfoBody}>
                <div className={styles.agentInfoBody1}>
                    <ProfilePicture
                        firstName={agentData?.agentFirstName}
                        id={lifeAgentTab}
                        lastName={agentData?.agentLastName}
                        profileUrl={agentData?.profileImageUrl}
                        hideCameraOverlay={true}
                        className={styles.agentPicture}
                    />
                    <NameWithStatus />
                </div>
                {isMobile && (
                    <div className={styles.addressDetailContainer}>
                        <div className={styles.subTextContainer}>
                            <Text
                                text={
                                    selectedProfilePlanType === medicarePlanType
                                        ? 'Health Agent '
                                        : `Life Agent`
                                }
                                className={styles.subTextBold}
                            />
                            <Text
                                text={`(${
                                    selectedProfilePlanType === medicarePlanType
                                        ? 'Medicare'
                                        : 'Final Expense'
                                }) |`}
                                className={styles.subText}
                            />
                            <Text
                                text={`(${experience} years)`}
                                className={styles.subText}
                            />
                        </div>
                        <div className={styles.subTextContainer}>
                            {(city || state) && (
                                <Text
                                    text={`${city}${
                                        city && state && ', '
                                    }${state}${(city || state) && ' | '}`}
                                    className={styles.subText}
                                />
                            )}

                            {streetAddress && (
                                <Text
                                    text={`${streetAddress}`}
                                    className={styles.subText}
                                />
                            )}
                        </div>

                        {formattedHours && availableHours.length === 1 && (
                            <div className={styles.subTextContainer}>
                                <Text
                                    text={availableHours}
                                    className={styles.subText}
                                />
                            </div>
                        )}

                        {availableHours?.length > 1 && (
                            <div className={styles.availableHoursContainer}>
                                {availableHours.map((hour, i) =>
                                    hour.length > 1 ? (
                                        <Text
                                            text={`${hour[0]}, ${hour[1]},`}
                                            className={styles.hourText}
                                            key={i}
                                        />
                                    ) : (
                                        <Text
                                            text={`${hour[0]}`}
                                            className={styles.hourText}
                                            key={i}
                                        />
                                    )
                                )}
                            </div>
                        )}

                        <LinkWithArrow
                            text={'View Agent'}
                            onClick={onClickViewMore}
                            className={styles.linkText}
                        />
                    </div>
                )}
                <AgentContactWithType />
                <AgentType
                    isNetworkAgent={isNetworkAgent}
                    isPreferredAgent={
                        selectedProfilePlanType === medicarePlanType
                            ? isPreferredAgentHealth
                            : isPreferredAgentLife
                    }
                />
            </div>
        );
    };

    const FindAnAgent = () => {
        return (
            <div>
                <div className={styles.noAgentProfile}>
                    <ProfilePicture
                        profileUrl={
                            selectedProfilePlanType === finalExpensePlanType
                                ? life
                                : health
                        }
                        hideCameraOverlay={true}
                        className={styles.noAgentProfilePicture}
                        styleProps={{
                            width: '160px',
                            height: '160px',
                            bgcolor: '#E2F4FD'
                        }}
                    />
                    <div className={styles.findAgentContainer}>
                        {errorResponse ? (
                            <>
                                <Text
                                    text={`ERROR`}
                                    className={styles.noAgentText}
                                />

                                <Text
                                    text={`Sorry! Something went wrong and this action could not be completed. Check back later.`}
                                    className={styles.errorText}
                                />
                            </>
                        ) : (
                            <>
                                <Text
                                    id="findAnAgentTitle"
                                    text={`Find a ${findAgentTitle}`}
                                    className={styles.noAgentText}
                                />

                                <LinkWithArrow
                                    text={'Contact an Agent'}
                                    className={styles.contactAgent}
                                    onClick={onClick}
                                />
                            </>
                        )}
                    </div>

                    {!errorResponse && (
                        <a
                            className={styles.linkNoAgent}
                            href={`tel:${number}`}
                            rel="noreferrer"
                        >
                            <Icon
                                className={`${styles.phoneIcon}`}
                                image={phoneIcon}
                            />
                            <Phone
                                id="noAgentPhoneText"
                                className={styles.noAgentPhone}
                                number={number}
                                text={text}
                            />
                        </a>
                    )}
                </div>
            </div>
        );
    };

    const LifeAgentContent = () => {
        const AntTab = styled(props => <Tab disableRipple {...props} />)(
            ({ theme }) => ({
                textTransform: 'none',
                '&.Mui-selected': {
                    color: '#052a63',
                    fontWeight: 700,
                    backgroundColor: '#faf8f6'
                }
            })
        );
        const AntTabs = styled(props => <Tabs disableRipple {...props} />)(
            ({ theme }) => ({
                textTransform: 'none',
                minHeight: 'auto',
                '.MuiTabs-flexContainer': {
                    backgroundColor: 'white'
                }
            })
        );

        return (
            <>
                <AntTabs
                    value={lifeAgentTab}
                    onChange={(e, value) => setLifeAgentTab(value)}
                    aria-label="Cost Tabs"
                    className={styles.lifeAgentTabs}
                    TabIndicatorProps={{
                        style: { display: 'none' }
                    }}
                >
                    {IUL_TOGGLE === 'true' && (
                        <AntTab
                            id="assingedAgentIndexedUniversalLifeTab"
                            label="Indexed Universal Life"
                            value="indexedUniversalLife"
                        />
                    )}
                    <AntTab
                        id="assinsgedAgentFinalExpenseTab"
                        label="Final Expense"
                        value="finalExpense"
                    />
                </AntTabs>
                <div>
                    {lifeAgentTab === 'finalExpense' ? (
                        <HealthAgentContent />
                    ) : (
                        <HealthAgentContent />
                    )}
                </div>
            </>
        );
    };

    const HealthAgentContent = () =>
        agentData?.email ? <AgentInfo /> : <FindAnAgent />;

    return tab === 0 ? <HealthAgentContent /> : <LifeAgentContent />;
};

export default memo(AssignedAgent);

import TextFieldWithLabel from 'components/TextFieldWithLabel';

import {
    placeholder,
    nameRegex,
    nameError,
    invalidPatternError,
    specialChars
} from './data';

import styles from './styles.module.scss';
import { useEffect } from 'react';

export const isValidFirstName = value => {
    return value && value.length > 1 ? nameRegex.test(value) : false;
};

const FirstNameTextField = ({
    autoFocus,
    defaultValue,
    disablePattern = false,
    error,
    id,
    labelText,
    register,
    className,
    noPlaceholder = false,
    labelClassName = styles.label,
    required,
    setError = () => {},
    clearErrors = id => {},
    value
}) => {
    const validate = value => {
        if (value && (value.length < 2 || !isValidFirstName(value))) {
            setError(id, {
                type: 'pattern'
            });
        } else {
            clearErrors(id);
        }
    };

    const onBlur = e => {
        const { value } = e.target;
        validate(value);
    };

    useEffect(() => {
        if (value) {
            validate(value);
        }
    }, [value]);

    return (
        <div className={`${className} ${styles.container}`}>
            <TextFieldWithLabel
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                error={error}
                errorMessage={nameError}
                errorMessagePattern={invalidPatternError}
                fieldClassName={styles.textField}
                id={id}
                onBlur={onBlur}
                labelClassName={labelClassName}
                labelText={labelText}
                pattern={disablePattern && nameRegex}
                placeholder={!noPlaceholder ? placeholder : ''}
                register={register}
                required={required}
                type="text"
            />
        </div>
    );
};

export default FirstNameTextField;
